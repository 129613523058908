<template>
<div class="landing-box">
	<div class="content-box">
		<div class="detail">
			<img src="@/assets/images/home/landing/detail.png" />
		</div>
		<div class="introduce">
			<img src="@/assets/images/home/landing/ysf.png" @click="onInsure(1)" style="margin-bottom: 12px;"/>
			<img src="@/assets/images/home/landing/yb.png" @click="onInsure()"/>
		</div>
	</div>
	<van-popup v-model="tipPopup" round>
		<div class="tip-popup">
			<div class="tip-content">
				<div class="tip-1">确认要放弃保费立减优惠嘛～</div>
				<div class="tip-2">
					已有<span>{{discounts}}</span>人使用优惠通道投保，</br>累计节省<span>{{discounts*10 | amount}}</span>保费
				</div>
			</div>
			<div class="tip-option">
				<div class="tip-btn" @click="onInsure(0)">仍要放弃</div>
				<div class="divide"></div>
				<div class="tip-btn active" @click="onInsure(1)">优惠通道</div>
			</div>
		</div>
	</van-popup>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
  data() {
    return {
			key: null,
			linkInsures: null,
			isSingle: false,
			tipPopup: false,
			discounts: 0
    }
  },
	computed: {
		...mapGetters(['getAgentKey']),
		isInUpApp() {
			var agent = navigator.userAgent.toLowerCase(); 
			var isInsideWallet = ((new RegExp(/(com.unionpay.chsp)/).test(agent)) || (new RegExp(/(com.unionpay.mobilepay)/).test(agent)));
			return isInsideWallet;
		}
	},
	filters: {
		amount (val) {
			if (val >= 10000) return val/10000 + '万'
			return val
		}
	},
  created() {
		this.key = this.getAgentKey || null
		// if (!this.key) return this.$toast.fail('缺少必要参数，请输入完整链接后重试');
		this.$API.queryJumpLink({dataKey: this.key}).then(res => {
			let {linkInsures, singleGroups} = res.data || {}
			if (linkInsures && linkInsures.length) this.linkInsures = linkInsures[0].shareUrl
			if (singleGroups && singleGroups.length) this.isSingle = true
			// this.isSingle = true
			// if (!this.linkInsures) this.linkInsures = "https://hmbcity.webao99.com/bj24/front-insured/?flag=OUT&channelType=PRIVATE&orderChannel=H5&projectid=124293784147386368&promotionId=AGENT_LEADER_125696249281142785&skuNo=BXHM0031#/home/124580188672811008"
		})
		this.$API.uniPayOrders().then(res => {
			this.discounts = (!res.data || res.data<0) ? 0 : res.data
		})
  },
	mounted() {
		let that = this
		upsdk.pluginReady (function(){
			upsdk.setTitleStyle({
			  navBackgroundColor:'0xffFC7A41',
			  appletStyle: 'white', //可选，black-黑色主题，white-白色主题
			  backBtnVisible: '0', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
			  appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
			});
			// 设置分享链接
			let link = that.$serviceLink
			if(that.getAgentKey) link = link + '?agentKey=' + that.getAgentKey
			upsdk.setAppletShareBaseInfo({
				title: "北京普惠健康保",  //  分享的标题	
				content: "政府指导，195元每年，最高保障300万元", //  分享的描述
				picUrl: "https://f2.youbaolian.top/ysf/img/ysf.png",  //  分享展示图片的url
				toLink: link,// 跳转地址
				shareIdList: ["0","1","3","4","5","6","7"], // 默认为全部渠道都有，可选值为：’0’,’1’,’3’,’4’,’5’,’6’,’7’
				success:function(){}
			})
		});
	},
  methods: {
		onInsure (type) {
			if (type === 0) {
				if (!this.linkInsures) return this.$toast('抱歉，暂不支持此方式投保')
				location.href = this.linkInsures
			} else if (type === 1) {
				if (!this.isInUpApp && !this.isSingle) return this.$toast('抱歉，暂不支持此方式投保')
				if (this.isInUpApp) { // 小程序环境
					this.$router.replace('/')
				} else {
					let json = {
					  "toLink" : encodeURIComponent(`${this.$serviceLink}?fast=1&agentKey=${this.getAgentKey}`),
					  "encryptAppId" : this.$appId
					}
					let params = btoa(JSON.stringify(json));
					let link = `https://base.95516.com/s/wl/WebAPP/helpAgree/page/help/shareRutineHelp.html?params=${params}`
					location.href = link
				}
			} else {
				if (this.isInUpApp || this.isSingle) this.tipPopup = true
				else this.onInsure(0)
			}
		}
  }
}
</script>

<style lang="less" scoped>
.landing-box {
	width: 100%;
	min-height: 100vh;
	background: linear-gradient(180deg, #FC7A41 0%, #F99962 100%);
	background-size: 100% 100%;
	.content-box {
		position: relative;
		z-index: 2;
		width: 100%;
		height: 100%;
		padding: 24px 0 50px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		box-sizing: border-box;
		.detail {
			width: 100%;
			min-height: 460px;
			img {
				width: 100%;
			}
		}
		.introduce {
			width: 100%;
			padding: 28px 42px;
			margin-top: -100px;
		}
	}
	/deep/ .van-popup--round {
		border-radius: 8px;
	}
}
.tip-popup {
	width: 686px;
	background-color: #ffffff;
	.tip-content {
		width: 100%;
		padding: 64px 48px;
		.tip-1 {
			font-size: 36px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #333333;
			text-align: center;
			margin-bottom: 24px;
		}
		.tip-2 {
			font-size: 34px;
			font-family: PingFangSC-Regular, PingFang SC;
			color: #666666;
			text-align: center;
			line-height: 56px;
			span {
				color: #FF9033;
			}
		}
	}
	.tip-option {
		width: 100%;
		height: 100px;
		border-top: solid 2px rgba(0,0,0,0.1);
		display: flex;
		align-items: center;
		.tip-btn {
			flex: 1;
			text-align: center;
			line-height: 100px;
			color: #576B95;
			font-size: 34px;
			&.active {
				color: #FF0904;
			}
		}
		.divide {
			width: 2px;
			height: 100%;
			background-color: rgba(0,0,0,0.1);
		}
	}
}
</style>